import { render, staticRenderFns } from "./ProcessRequest.vue?vue&type=template&id=20347f55&scoped=true&"
import script from "./ProcessRequest.vue?vue&type=script&lang=js&"
export * from "./ProcessRequest.vue?vue&type=script&lang=js&"
import style0 from "./ProcessRequest.vue?vue&type=style&index=0&id=20347f55&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20347f55",
  null
  
)

export default component.exports
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCircularProgress,QSpace,QChip,QAvatar,QChatMessage,QMenu});
